import Logo from "../../assets/img/logo-light.png";
import {useEffect} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {motion} from "framer-motion"
import {useDispatch} from "react-redux";
import {
  setCurrency,
  setCurrencySymbol,
  setGoogleCommentUrl,
  setIsGoogleComment,
  setIsOrderAvailable,
  setIsRequiredNoteScreen,
  setIsShowFeedbackForm,
  setMenuId,
  setMenuInfo,
  setRestaurantId,
  setShowProductOptions,
  setTableId,
  setTheme,
  setThemeOption
} from "../../storage/app";
import {useGetMenuInfoQuery} from "../../storage/api";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";


const SplashScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {restaurantId} = useParams();
  const [searchParams] = useSearchParams();
  const tableId = searchParams.get('table_id');
  const menuId = searchParams.get('menu_id');
  const option = searchParams.get('theme_option');
  const queryTheme = searchParams.get('theme');
  const MySwal = withReactContent(Swal)

  useEffect(() => {
    dispatch(setTableId(tableId));
    dispatch(setRestaurantId(restaurantId));
    dispatch(setMenuId(menuId));
    dispatch(setThemeOption(option || 'option-1'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId, tableId, menuId]);

  const {data, error, isLoading} = useGetMenuInfoQuery({menuId, tableId});

  useEffect(() => {
    if (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Hata!',
        html: 'Menü Bulunamadı!',
        showCancelButton: false,
        showConfirmButton: false,
        confirmButtonText: 'Tamam',
        allowOutsideClick: false,
        backdrop: false,
      }).then((result) => {

      })
    } else {
      if (!isLoading) {
        dispatch(setMenuInfo(data))
        dispatch(setCurrency(data?.currency || 'TRY'))
        dispatch(setCurrencySymbol(data?.currencySymbol || '₺'))
        dispatch(setIsShowFeedbackForm(data?.feedback_form_enabled))
        dispatch(setIsRequiredNoteScreen(data?.name_required_for_order))
        dispatch(setIsGoogleComment(data?.is_google_comment || false))
        if (data?.is_google_comment) {
          dispatch(setGoogleCommentUrl(data?.google_comment_url || ''))
        }
        dispatch(setIsOrderAvailable(data?.allow_order && Boolean(tableId)))
        dispatch(setShowProductOptions(data?.show_product_options || data?.allow_order))
        dispatch(setTheme(queryTheme || data?.theme))

        if (Boolean(data)) {
          setTimeout(() => {
            return navigate("/home");
          }, 1500);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error])

  return (
    <div className={'bg-[#021321] text-white h-screen w-full flex justify-center items-center'} style={{}}>
      <motion.div
        className={'flex justify-center items-center'}
        initial={{opacity: 0, scale: 0.5}}
        animate={{opacity: 1, scale: 1}}
        transition={{duration: 1}}>
        <img src={Logo} width={'60%'} alt={'Logo'}/>
      </motion.div>
    </div>
  )
}

export default SplashScreen;